<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 14:06:23
 * @Description: 请您输入文件描述
-->
<template>
    <div class="feichi_all_area_chart_model">
        <div class="feichi_all_area_chart">
            <div id="allAreaChart" :style="{width: '250px', height: '250px'}"></div>
            <div class="all_area_text_model">
                <p class="all_area_number">{{ $store.state.feichiDataObj.all_building }}<span>(万m²)</span></p>
                <p class="all_area_name">总建筑面积</p>
            </div>
        </div>
        <div class="feichi_area_detail">
            <div class="area_detail_item">
                <div class="area_detail_icon"></div>
                <div class="area_detail_text">
                    <p class="area_detail_text_num lang_up_num">{{ $store.state.feichiDataObj.road_building }}</p>
                    <p>地上建筑面积<span style="font-size: 12px">(万m²)</span></p>
                </div>
            </div>
            <div class="area_detail_item">
                <div class="area_detail_icon area_detail_icon_two"></div>
                <div class="area_detail_text">
                    <p class="area_detail_text_num lang_down_num">{{ $store.state.feichiDataObj.underground_building }}</p>
                    <p>地下建筑面积<span style="font-size: 12px">(万m²)</span></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        mounted () {
            this.drawLine()
            /* // console.log(this.getPercent(this.$store.state.feichiDataObj.all_building, this.$store.state.feichiDataObj.road_building))
            // console.log(this.getPercent(this.$store.state.feichiDataObj.all_building, this.$store.state.feichiDataObj.underground_building)) */
        },
        methods: {
            drawLine() {
                let _all = this.$store.state.feichiDataObj.all_building
                let _top = this.$store.state.feichiDataObj.road_building
                let _bottom = this.$store.state.feichiDataObj.underground_building
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById('allAreaChart'))
                // 绘制图表
                myChart.setOption({
                    legend: {
                        show: false,
                        orient: 'vertical',
                    },
                    color: [ '#1bc998', '#014976' ],
                    series: [
                        {
                        type: 'pie',
                        radius: ['45%', '60%'],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.getPercent(_all,_top), name: 'Direct' },
                            { value: this.getPercent(_all,_bottom), name: 'Email' },
                        ]
                        }
                    ]
                })
            },
            getPercent (allNum, num) {
                num = parseFloat(num)
                allNum = parseFloat(allNum)
                if (isNaN(num) || isNaN(allNum)) {
                    return '0'
                }
                return allNum <= 0 ? 0 : Number((Math.round(num / allNum * 10000) / 100.00).toFixed(0))
            }
        }
    }
</script>
<style lang="less">
    @wrpa-color: #03dbaa;
    .feichi_all_area_chart_model {
        .feichi_all_area_chart {
            position: relative;
            height: 250px;
            width: 230px;
            margin-top: -25px;
            float: left;
            .all_area_text_model {
                position: absolute;
                top: calc(50% - 66px / 2);
                left: calc(55% - 100px / 2);
                text-align: center;
                height: 66px;
                width: 100px;
                .all_area_number {
                    font-size: 30px;
                    font-weight: 500;
                    height: 35px;
                    span {
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
                .all_area_name {
                    font-size: 16px;
                }
            }
        }
        .feichi_area_detail {
            height: 106px;
            width: 134px;
            /* background: green; */
            float: left;
            margin-top: 52px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            .area_detail_item {
                height: 43px;
                width: 100%;
                /* background: #6f6f8d; */
                display: flex;
                justify-content: space-between;
                .area_detail_icon {
                    position: relative;
                    width: 7px;
                    height: 7px;
                    background: @wrpa-color;
                }
                .area_detail_icon::after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 36px;
                    background: @wrpa-color;
                    top: 7px;
                    left: calc(50% - 1px / 2);
                }
                .area_detail_text {
                    .area_detail_text_num {
                        font-size: 28px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                    }
                    .lang_up_num {
                        color:  @wrpa-color;
                    }
                    .lang_down_num {
                        color: #0262a5;
                    }
                }
                .area_detail_icon_two {
                    background: rgba(0, 148, 255, 0.6);
                }
                .area_detail_icon_two::after {
                    background: rgba(0, 148, 255, 0.6);
                }
            }
        }
    }
</style>