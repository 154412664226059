<template>
    <div class="feiChi_home_model">
        <div v-if="$store.state.mapType" class="feichi_home_header"></div>
        <FeiChiHomeLeft v-if="$store.state.animateDelay && $store.state.mapType"
            :class="{ 'animate__delay-0.5s': $store.state.animateDelay, 'animate__animated': true, 'animate__fadeInLeftBig': $store.state.showDataValue, 'animate__fadeOutLeftBig': !$store.state.showDataValue }"
            style="position: relative;z-index: 1000;" />
        <FeiChiHomeRight v-if="$store.state.animateDelay && $store.state.mapType"
            :class="{ 'animate__delay-0.5s': $store.state.animateDelay, 'animate__animated': true, 'animate__fadeInRightBig': $store.state.showDataValue, 'animate__fadeOutRightBig': !$store.state.showDataValue }"
            style="position: relative;z-index: 1000;" />
        <!-- <div v-if="$store.state.mapType" class="feichi_home_fotter"></div> -->
    </div>
</template>
<script>
import FeiChiHomeLeft from '@/components/feichi/FeiChiHomeLeft.vue'
import FeiChiHomeRight from '@/components/feichi/FeiChiHomeRight.vue'
export default {
    name: 'feiChiHome',
    components: {
        FeiChiHomeLeft,
        FeiChiHomeRight
    },
    data() {
        return {
            screenWidth: document.body.clientWidth,
            scale: document.body.clientWidth / 1920
        }
    },
    mounted() {
        // const that = this
        window.onload = function () {
            adjust()
            window.onresize = function () {
                adjust()
            }
        }
        let adjust = function () {
            let winWidth = document.documentElement.clientWidth
            // console.log(winWidth)
            let body = document.getElementsByTagName('body')[0]
            // let winHeight = document.body.clientHeight
            // 获取屏幕的宽度 和高度
            let scale = winWidth / 1920
            // // console.log(scale)
            body.style.cssText = 'transform:scale(' + scale + ');'
        }
        this.$store.dispatch('getFeichiData')
    },
    watch: {
        /* 监听*/
        screenWidth(val) {
            this.screenWidth = val;
            // console.log("this.screenWidth", this.screenWidth)
        }
    },
    computed: {
        scaleFun: function () {
            var scale = this.scale;
            return `transform:scale(${scale})`
        }
    }
}
</script>
<style lang="less">
.feiChi_home_model {
    width: 1920px;
    height: 1080px;
    color: #fff;
    position: relative;

    /* background: url('./../../assets/images/feichi_bg2.png') no-repeat; */
    /* background-size: 100% 87.5%; */
    .feichi_home_header {
        position: absolute;
        height: 89px;
        background: url('./../../assets/images/feichi_header.png') no-repeat;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .feichi_home_fotter {
        position: absolute;
        height: 48px;
        background: url('./../../assets/images/feichi_fotter.png') no-repeat;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 1001;
    }
}
</style>