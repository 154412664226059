<template>
    <div class="feichi_greening_chart_model">
        <div class="feichi_greening_chart_content">
            <div class="greening_dashed_round"></div>
            <div id="greeningChart" :style="{ width: '250px', height: '250px' }"></div>
            <ul class="gap_model">
                <li v-for="(item, index) in gapNum" :key="index" :style="{ 'transform': `rotate(${index * 30}deg)` }">
                    <div></div>
                </li>
            </ul>
            <div class="green_round_model"></div>
            <div class="feichi_green_round_chart">
                <div class="feichi_greening_round_gap">
                    <ul>
                        <li v-for="(item, index) in roundGap" :key="index"
                            :style="{ 'transform': `rotate(${index * 12}deg)` }">
                        </li>
                    </ul>
                    <ul class="greening_yes_pro">
                        <li v-for="(item, index) in greenYesGap" :key="index"
                            :style="{ 'transform': `rotate(${index * 12}deg)` }">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="greening_pro_text">
                <p class="greening_pro_number">
                    {{ $store.state.feichiDataObj.greening_rate ? Number($store.state.feichiDataObj.greening_rate) : 0
                    }}
                    <span style="font-size: 12px">(%)</span>
                </p>
                <p>绿化率</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            gapNum: 14,
            roundGap: 30,
            greenYesGap: 0
        }
    },
    mounted() {
        this.drawLine()
        const _num = this.$store.state.feichiDataObj.greening_rate ? Number(this.$store.state.feichiDataObj.greening_rate) : 0
        this.greenYesGap = this.getPercent(100, _num)
    },
    methods: {
        drawLine() {
            let _greenNum = this.$store.state.feichiDataObj.greening_rate ? Number(this.$store.state.feichiDataObj.greening_rate) : 0
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('greeningChart'))
            // 绘制图表
            myChart.setOption({
                legend: {
                    show: false,
                    orient: 'vertical',
                },
                color: ['#1bc998', 'rgba(27, 201, 152, 0.06)'],
                series: [
                    {
                        type: 'pie',
                        radius: ['47%', '60%'],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: _greenNum, name: 'Direct' },
                            { value: 100 - _greenNum, name: 'Email' },
                        ]
                    }
                ]
            })
        },
        getPercent(allNum, num) {
            num = parseFloat(num)
            allNum = parseFloat(allNum)
            if (isNaN(num) || isNaN(allNum)) {
                return '0'
            }
            return allNum <= 0 ? 0 : Number((Math.round(num / allNum * 3000) / 100.00).toFixed(0))
        }
    }
}
</script>
<style lang="less">
.feichi_greening_chart_model {
    .feichi_greening_chart_content {
        position: relative;
        width: 250px;
        height: 250px;
        margin: 0 auto;
        margin-top: -20px;

        .gap_model {
            li {
                width: 6px;
                height: 24px;
                position: absolute;
                left: 123px;
                top: 69px;
                margin-top: -20px;
                transform-origin: 2px 77px;
                background: rgba(1, 35, 44, 0.9);
            }
        }

        .feichi_green_round_chart {
            position: absolute;
            top: 0;

            .feichi_greening_round_gap {
                width: 102px;
                height: 102px;
                border-radius: 50%;
                position: relative;

                li {
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    background: #026856;
                    border-radius: 50%;
                    left: 145px;
                    top: 96px;
                    margin-left: -20px;
                    margin-top: -20px;
                    transform-origin: 0px 50px;
                    /* div{
                            background: #026856;
                            width: 100%;
                            height: 100%;
                            transform: rotate(-20deg);
                        } */
                }

                .greening_yes_pro {
                    li {
                        background: #03dbaa;
                    }
                }
            }
        }

        .green_round_model {
            width: 108px;
            height: 108px;
            position: absolute;
            top: calc(50% - 110px / 2);
            left: calc(50% - 112px / 2);
            border-radius: 50%;
            border: 2px solid rgba(3, 159, 126, 0.44);
        }

        .greening_pro_text {
            position: absolute;
            top: calc(50% - 60px / 2);
            /* width: 52px;
                height: 46px; */
            left: calc(50% - 52px / 2);
            text-align: center;

            .greening_pro_number {
                font-size: 32px;
                font-weight: 500;
                height: 36px;
            }
        }

        .greening_dashed_round {
            position: absolute;
            width: 170px;
            height: 170px;
            border: 1px dotted rgba(3, 159, 126, 0.8);
            top: calc(50% - 172px / 2);
            left: calc(50% - 172px / 2);
            border-radius: 50%;
        }
    }
}
</style>