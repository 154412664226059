<template>
  <div class="feichi_home_right_model">
    <div class="feichi_home_right_content">
      <div :class="{ feichi_one_gmp_model: true, show_width: showWidth }">
        <div class="feichi_all_title">单栋GMP厂房概况</div>
        <div class="one_gmp_overview">
          <div class="one_gmp_box">
            <div class="one_gmp_item">
              <div class="one_gmp_icon gmp_icon_bg1">
                <img class="gmp_round_move" src="@/assets/images/feichi_right_round1.png" alt="" />
                <div class="gmp_center_icon">
                  <img src="@/assets/images/feichi_left_icon1.png" alt="" />
                </div>
              </div>
              <p class="one_gmp_item_num">
                <ICountUp
                  :delay="1000"
                  :endVal="$store.state.feichiDataObj.one_gmp_road_rate ? Number($store.state.feichiDataObj.one_gmp_road_rate) : 0"
                  :options="{ separator: ',', decimalPlaces: '0' }"
                />
              </p>
              <p>地上建筑面积<span style="font-size: 12px">(m²)</span></p>
            </div>
            <div class="one_gmp_item">
              <div class="one_gmp_icon gmp_icon_bg2">
                <img class="gmp_round_move" src="@/assets/images/feichi_right_round2.png" alt="" />
                <div class="gmp_center_icon">
                  <img src="@/assets/images/feichi_right_icon1.png" alt="" />
                </div>
              </div>
              <p class="one_gmp_item_num one_gmp_item_num_two">
                <ICountUp
                  :delay="1000"
                  :endVal="$store.state.feichiDataObj.one_gmp_underground_rate ? Number($store.state.feichiDataObj.one_gmp_underground_rate) : 0"
                  :options="{ separator: ',', decimalPlaces: '0' }"
                />
              </p>
              <p>地下建筑面积<span style="font-size: 12px">(m²)</span></p>
            </div>
          </div>
          <div class="one_gmp_box">
            <div class="one_gmp_item">
              <div class="one_gmp_icon gmp_icon_bg3">
                <img class="gmp_round_move" src="@/assets/images/feichi_right_round3.png" alt="" />
                <div class="gmp_center_icon">
                  <img src="@/assets/images/feichi_right_icon2.png" alt="" />
                </div>
              </div>
              <p class="one_gmp_item_num one_gmp_item_num_three">
                <ICountUp :delay="1000" :endVal="$store.state.feichiDataObj.one_gmp_floor ? Number($store.state.feichiDataObj.one_gmp_floor) : 0" :options="{ separator: ',', decimalPlaces: '0' }" />
              </p>
              <p>层数<span style="font-size: 12px">(层)</span></p>
            </div>
            <div class="one_gmp_item">
              <div class="one_gmp_icon gmp_icon_bg4">
                <img class="gmp_round_move" src="@/assets/images/feichi_right_round4.png" alt="" />
                <div class="gmp_center_icon">
                  <img src="@/assets/images/feichi_right_icon3.png" alt="" />
                </div>
              </div>
              <p class="one_gmp_item_num one_gmp_item_num_four">
                <ICountUp :delay="1000" :endVal="$store.state.feichiDataObj.one_gmp_height ? Number($store.state.feichiDataObj.one_gmp_height) : 0" :options="{ separator: ',', decimalPlaces: '0' }" />
              </p>
              <p>楼幢高度<span style="font-size: 12px">(米)</span></p>
            </div>
          </div>
        </div>
      </div>
      <div :class="{ feichi_greening_model: true, show_width: showWidth }">
        <div class="feichi_all_title">厂房绿化率</div>
        <div class="feichi_greening_content">
          <FeichiGreeningChart v-if="showGreenModel" />
        </div>
      </div>
      <div :class="{ feichi_parking_model: true, show_width: showWidth }">
        <div class="feichi_all_title">机动车停车位</div>
        <div class="feichi_parking_content">
          <div class="feichi_parking_allCardNum">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div class="parking_allCardNum_text_model">
              <div class="parking_cardIcon_model">
                <div class="parking_card_icon">
                  <img src="@/assets/images/parking_card_icon.png" alt="" />
                </div>
                <div class="parking_linear_item">
                  <img src="@/assets/images/parking_icon_two.png" alt="" />
                </div>
                <div class="parking_bottom_round"></div>
              </div>
              <div class="parking_cardNumber_text">
                <p class="parking_card_number parking_card_color">{{ allCard }}</p>
                <p>总停车位<span style="font-size: 12px">(辆)</span></p>
              </div>
            </div>
          </div>
          <div class="card_rented_out">
            <p class="parking_card_number parking_card_color">
              <!-- {{ readerOut }} -->
              <ICountUp :delay="1000" :endVal="readerOut" :options="{ separator: ',', decimalPlaces: '0' }" />
            </p>
            <p class="parking_card_name">已出租<span style="font-size: 12px">(辆)</span></p>
            <ul class="rented_out_schedule">
              <li v-for="(item, index) in getPercent(allCard, readerOut)" :key="index" class="rented_out_schedule_item"></li>
            </ul>
          </div>
          <div class="card_rented_no">
            <p class="parking_card_number parking_card_color">
              <!-- {{ readerNo }} -->
              <ICountUp :delay="1000" :endVal="readerNo" :options="{ separator: ',', decimalPlaces: '0' }" />
            </p>
            <p class="parking_card_name">未出租<span style="font-size: 12px">(辆)</span></p>
            <ul class="rented_out_schedule">
              <li v-for="(item, index) in noNum" :key="index" class="rented_out_schedule_item"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/feichi/feichiHomeRight.less";
import FeichiGreeningChart from "@/components/feichi/FeichiGreeningChart";
import ICountUp from "vue-countup-v2";
export default {
  components: {
    FeichiGreeningChart,
    ICountUp,
  },
  data() {
    return {
      allCard: 0,
      readerOut: 0,
      readerNo: 0,
      roundGap: 30,
      greenYesGap: 8,
      showWidth: true,
      showGreenModel: false,
      noNum: 0,
      outNum: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showGreenModel = true;
    }, 1000);
    setTimeout(() => {
      this.allCard = this.$store.state.feichiDataObj.car_num ? Number(this.$store.state.feichiDataObj.car_num) : 0;
      this.readerNo = this.$store.state.feichiDataObj.not_rent ? Number(this.$store.state.feichiDataObj.not_rent) : 0;
      this.readerOut = this.$store.state.feichiDataObj.car_rent ? Number(this.$store.state.feichiDataObj.car_rent) : 0;
      // console.log(this.readerOut, this.readerNo);
      const _allReaderNo = this.getPercent(this.allCard, this.readerNo);
      const _allReaedOut = this.getPercent(this.allCard, this.readerOut);
      if (_allReaedOut) {
        let readerOut = setInterval(() => {
          this.outNum++;
          if (this.outNum >= _allReaedOut) {
            clearInterval(readerOut);
          }
        }, 150);
      }
      if (_allReaderNo) {
        let readerNo = setInterval(() => {
          this.noNum++;
          if (this.noNum >= _allReaderNo) {
            clearInterval(readerNo);
          }
        }, 150);
      }
    }, 2000);
  },
  methods: {
    getPercent(allNum, num) {
      num = parseFloat(num);
      allNum = parseFloat(allNum);
      if (isNaN(num) || isNaN(allNum)) {
        return "0";
      }
      return allNum <= 0 ? 0 : Number((Math.round((num / allNum) * 2000) / 100.0).toFixed(0));
    },
  },
};
</script>
