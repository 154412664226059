<template>
    <div class="feichi_home_left_model">
        <div class="feichi_home_left_content">
            <div :class="{'feichi_building_model' : true, 'feichi_width' : showWidth}">
                <div class="feichi_all_title">
                    项目介绍
                </div>
                <div class="feichi_building_content">
                    <div class="feichi_building_area_model">
                        <div class="feichi_building_area_item">
                            <div class="feichi_building_icon feichi_all_area_icon area_icon_bg1">
                                <img src="@/assets/images/feichi_left_icon1.png" alt="">
                            </div>
                            <div class="feichi_all_area_text">
                                <p class="feichi_area_number feichi_area_color1">
                                    <!-- 3.34 -->
                                    <ICountUp
                                        :delay="1000"
                                        :endVal="$store.state.feichiDataObj.all_lands ? Number($store.state.feichiDataObj.all_lands) : 0"
                                        :options="{separator: ',', decimalPlaces: '2'}"
                                    />
                                </p>
                                <p>总用地面积 <span style="font-size: 12px">(万m²)</span></p>
                            </div>
                        </div>
                        <div class="feichi_building_area_item">
                            <div class="feichi_building_icon feichi_all_area_icon area_icon_bg2">
                                <img src="@/assets/images/feichi_left_icon2.png" alt="">
                            </div>
                            <div class="feichi_all_area_text">
                                <p class="feichi_area_number feichi_area_color2">
                                    <!-- 1.3 -->
                                    <ICountUp
                                        :delay="1000"
                                        :endVal="$store.state.feichiDataObj.volume_rate ? Number($store.state.feichiDataObj.volume_rate) : 0"
                                        :options="{separator: ',', decimalPlaces: '1'}"
                                    />
                                </p>
                                <p>容积率</p>
                                <!-- <p>容积率 <span style="font-size: 12px">(%)</span></p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <FeiChiAllAreaChart v-if="showAreaChart" />
            </div>
            <div :class="{'feichi_all_area_model': true, 'feichi_all_one_area': true, 'feichi_width' : showWidth}">
                <div class="feichi_all_title">
                    GMP车间建筑面积
                </div>
                <div class="feichi_workshop_model">
                    <ul class="feichi_workshop_list">
                        <li class="feichi_workshop_item">
                            <div class="workshop_icon feichi_all_area_icon area_icon_bg1">
                                <img src="@/assets/images/feichi_left_icon1.png" alt="">
                            </div>
                            <p class="workshop_number workshop_num_color1">
                                <!-- 4.72 -->
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.gmp_building ? Number($store.state.feichiDataObj.gmp_building) : 0"
                                    :options="{separator: ',', decimalPlaces: '2'}"
                                />
                            </p>
                            <p>建筑面积<span style="font-size: 12px;">(万m²)</span></p>
                        </li>
                        <li class="feichi_workshop_item">
                            <div class="workshop_icon feichi_all_area_icon area_icon_bg2">
                                <img src="@/assets/images/feichi_left_icon3.png" alt="">
                            </div>
                            <p class="workshop_number workshop_num_color2">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.gmp_floor ? Number($store.state.feichiDataObj.gmp_floor) : 0"
                                    :options="{separator: ',', decimalPlaces: '0'}"
                                />
                            </p>
                            <p>楼幢<span style="font-size: 12px;">(栋)</span></p>
                        </li>
                        <li class="feichi_workshop_item">
                            <div class="workshop_icon feichi_all_area_icon area_icon_bg3">
                                <img src="@/assets/images/feichi_left_icon4.png" alt="">
                            </div>
                            <p class="workshop_number workshop_num_color3">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.gmp_all_floor ? Number($store.state.feichiDataObj.gmp_all_floor) : 0"
                                    :options="{separator: ',', decimalPlaces: '0'}"
                                />
                            </p>
                            <p>总楼层<span style="font-size: 12px;">(层)</span></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div :class="{'feichi_all_area_model': true, 'feichi_all_two_area': true, 'feichi_width' : showWidth}">
                <div class="feichi_all_title">
                    公寓建筑面积
                </div>
                <div class="feichi_workshop_model">
                    <ul class="feichi_workshop_list">
                        <li class="feichi_workshop_item">
                            <div class="apartment_icon apartment_color1">
                                <img src="@/assets/images/feichi_left_icon1.png" alt="">
                                 <div class="apartment_move_border"></div>
                            </div>
                            <p class="workshop_number workshop_num_color1">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.apartment_floor ? Number($store.state.feichiDataObj.apartment_floor) : 0"
                                    :options="{separator: ',', decimalPlaces: '1'}"
                                />
                            </p>
                            <p>建筑面积<span style="font-size: 12px;">(万m²)</span></p>
                        </li>
                        <li class="feichi_workshop_item">
                            <div class="apartment_icon apartment_color2">
                                <img src="@/assets/images/feichi_left_icon3.png" alt="">
                                 <div class="apartment_move_border"></div>
                            </div>
                            <p class="workshop_number workshop_num_color2">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.apartment_building ? Number($store.state.feichiDataObj.apartment_building) : 0"
                                    :options="{separator: ',', decimalPlaces: '0'}"
                                />
                            </p>
                            <p>楼幢<span style="font-size: 12px;">(栋)</span></p>
                        </li>
                        <li class="feichi_workshop_item">
                            <div class="apartment_icon apartment_color3">
                                <img src="@/assets/images/feichi_left_icon4.png" alt="">
                                 <div class="apartment_move_border"></div>
                            </div>
                            <p class="workshop_number workshop_num_color3">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.apartment_all_floor ? Number($store.state.feichiDataObj.apartment_all_floor) : 0"
                                    :options="{separator: ',', decimalPlaces: '0'}"
                                />
                            </p>
                            <p>总楼层<span style="font-size: 12px;">(层)</span></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div :class="{'feichi_all_area_model': true, 'feichi_width' : showWidth}">
                <div class="feichi_all_title">
                    商业建筑面积
                </div>
                <div class="feichi_workshop_model">
                    <ul class="feichi_workshop_list">
                        <li class="feichi_workshop_item">
                            <div class="business_icon business_color1">
                                <img src="@/assets/images/feichi_left_icon1.png" alt="">
                                <div class="business_hover_border"></div>
                                <div class="business_move_border"></div>
                            </div>
                            <p class="workshop_number workshop_num_color1">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.business_building ? Number($store.state.feichiDataObj.business_building) : 0"
                                    :options="{separator: ',', decimalPlaces: '0'}"
                                />
                            </p>
                            <p>建筑面积<span style="font-size: 12px;">(m²)</span></p>
                        </li>
                        <li class="feichi_workshop_item">
                            <div class="business_icon business_color2">
                                <img src="@/assets/images/feichi_left_icon3.png" alt="">
                                <div class="business_hover_border"></div>
                                <div class="business_move_border"></div>
                            </div>
                            <p class="workshop_number workshop_num_color2">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.business_floor ? Number($store.state.feichiDataObj.business_floor) : 0"
                                    :options="{separator: ',', decimalPlaces: '0'}"
                                />
                            </p>
                            <p>楼幢<span style="font-size: 12px;">(栋)</span></p>
                        </li>
                        <li class="feichi_workshop_item">
                            <div class="business_icon business_color3">
                                <img src="@/assets/images/feichi_left_icon4.png" alt="">
                                <div class="business_hover_border"></div>
                                <div class="business_move_border"></div>
                            </div>
                            <p class="workshop_number workshop_num_color3">
                                <ICountUp
                                    :delay="1000"
                                    :endVal="$store.state.feichiDataObj.business_all_floor ? Number($store.state.feichiDataObj.business_all_floor) : 0"
                                    :options="{separator: ',', decimalPlaces: '0'}"
                                />
                            </p>
                            <p>总楼层<span style="font-size: 12px;">(层)</span></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import '@/assets/css/feichi/feichiHomeLeft.less'
    import FeiChiAllAreaChart from '@/components/feichi/FeiChiAllAreaChart'
    import ICountUp from 'vue-countup-v2';
    export default {
        components: {
            FeiChiAllAreaChart,
            ICountUp
        },
        data () {
            return {
                showWidth: true,
                showAreaChart: false
            }
        },
        mounted () {
            setTimeout(() => {
                // console.log(11111)
                this.showAreaChart = true
            },1000)
        }
    }
</script>